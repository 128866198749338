

import API from './api'


export default async (catalogo)  =>{
  try {
    const response =   await API.get(catalogo)
    const data = await response.data
    data.unshift({value: "", label: '--Selecciona un valor--'})
    return data;
  } catch (error) {
    const errorA=[{value: "", label: '--Selecciona un valor--'}]
    return errorA;
  }

        
}