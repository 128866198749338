import React
// , { useEffect,useState} 
from 'react';
//  import history from './../librerias/history'
import { useForm } from 'react-hook-form'
import funciones from './../librerias/funciones'
import API from './../librerias/api'
import './../login.css';
import logo from './../logo.png'; 
import logosisdesa from './../logo_sisdesa_blanco.png'; 

const Login =({cambiarUsuario})=>{
    const { register, handleSubmit, errors  } = useForm()

  

    const onSubmit =async (data,e) => { 
        try {
            const response =  await API.post('auth/login', data);
            if(response.status===200){
             localStorage.setItem("user", JSON.stringify(response.data));
             window.location.replace('');
            }
          } catch (error) {
            funciones.tratarerrores(error,document.getElementById("respuesta"));
          }
       }
    return(
            <div className="container-login">
              <div className="row-clearfix" style={{paddingTop:"103px"}}>
              <div className="card container pt-5" style={{boxShadow:"none",background:"none",border:"none",margin:"0 auto -79px" }} >
                <img id="profile-img" className="profile-img-card"  src={logo} /> 
              </div>
              <div className="card card-container">
                <form className="form-signin " onSubmit={handleSubmit(onSubmit)}>
                  <h1 className="titulo text-center pt-3">Iniciar sesión</h1>
                  <span id="reauth-email" className="reauth-email"></span>
                  <label className="mb-0"><h3 style={{color:"rgb(52 52 52)",fontSize: "1.10rem"}}>Usuario</h3></label>
                  <input type="email" name="email" ref={register({ required: true })}  className="form-control mb-4" placeholder="Correo/Usuario"/>
                  {errors.email &&
                  <div className="error-form text-red p-small">
                    ¡Este campo no puede estar vacío!
                  </div>
                  }
                  <label className="mb-0"><h3 style={{color:"rgb(52 52 52)",fontSize: "1.10rem"}}>Contraseña</h3></label>
                  <input type="password" name="password" className="form-control" placeholder="Contraseña" ref={register({ required: true })}  />
                  {errors.password &&
                  <div className="error-form text-red p-small">
                    ¡Este campo no puede estar vacío!
                  </div>
                  }
                  <div id="respuesta" className="text-red">

                  </div>
                  {/* invalid-feedback */}
                  <div className="row pt-2" >
                  <div className="col-12 pt-1">
                        <button className="btn btn-primary mb-2 mr-1" type="submit">Iniciar sesión</button>
                    </div>
                    <div className="col-12 text-center pb-3">
                      <a href="#" className="forgot-password pt-3 p-small">
                      Olvidé mi contraseña
                      </a>
                    </div>
                    
                  </div>
                </form>
                {/* <hr></hr>
                <p className="text-center p-small" >
                  Marcas Registradas: Tecnoland Copyright 2004 - 2020
                  <br/>
                  Todos los derechos reservados. Nros. de registro 9.82, 2.112.927
                  <br/>
                  *Si no rer usuario de este sistema favor de abandonar el sitio*
                </p> */}
              </div>
              <div id="logo-sisdesa-login" >
                <div className="bloque-footer-login" >
                  <p className="p-footer-login text-white">
                  Desarrolado por:
                  </p>
                </div>
                <div className="bloque-footer-login" >
                  <img id="profile-img" className="img-sisdesa-login"  src={logosisdesa} />
                </div>
              </div>

              </div>
              
            </div>




    )
    
  }
  export default Login;
