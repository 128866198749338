import {useState, useEffect} from 'react'
import funciones from './funciones'
import API from './api'


export default (URLLEER,id,get=0)  =>{
    const [data, setData] = useState({});
    const obtenerData = async (URLLEER,id) =>{
      // si trae un "1" en parametro get es get
      try {
        if(get===0){
          const response =   await API.post(URLLEER,funciones.getFormData({id}))
          const data = await response.data
          setData(data);
        }else{
          const response =   await API.get(URLLEER+id)
          const data = await response.data
          setData(data);
        }
      } catch (error) {
        if(document.getElementById("respuesta")){
          funciones.tratarerrores(error,document.getElementById("respuesta"));
        }
        console.log(error)
      }
    
      }
      useEffect(
        ()=>{
             obtenerData(URLLEER,id)
        },[URLLEER,id]
      )
      return data;
        
}