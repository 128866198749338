import React, { useEffect }
// , { useEffect,useState} 
from 'react';
//  import history from './../librerias/history'
import funciones from './../librerias/funciones'
import API from './../librerias/api'

const Cerrar =()=>{

  

    const salir =async () => { 
        try {
          const response =  await API.post('auth/logout');
          if(response.status===200){
             localStorage.setItem("user", JSON.stringify(response.data));
             localStorage.clear();
             window.location.replace('');
          }
          } catch (error) {
            funciones.tratarerrores(error,document.getElementById("respuesta"));
           
          }
  
       }
    return(
        <>
         <button onClick={ ()=>{salir()} } >Salir</button>
        </>
    )

}
export default Cerrar;