import React, {useState, useCallback} from 'react'
import { Link } from 'react-router-dom';

export default({buscarData, icono,ruta, permiso}) =>{
    const [buscar,setBuscar] = useState('');
   // useCallback//https://es.reactjs.org/docs/hooks-reference.html#callback

    permiso = permiso.Crear === 1 ? true : false;
    const buscarDataPalabra = useCallback(
        () => {
            buscarData(buscar);
        },
        [buscar,buscarData],
      );
    return (
        <div className="input-group col-md-7 abosule-right">
            <input type="text" className="form-control" style={{padding: "0.45rem 1.07rem"}} onChange={ e => setBuscar (e.target.value)} placeholder="Buscar..." aria-label="Buscar..." aria-describedby="button-addon2"/>
            <div className="input-group-append ml-4">
                <button className="btn btn-outline-secondary" type="button" id="button-addon2" onClick={ buscarDataPalabra } >Buscar</button>
            </div>
            {
                permiso ?
                <Link to={ruta}>
                <img  src={icono} style={{maxWidth:"55px"}} alt="settings" className="pl-3" ></img>
                </Link>
                :
                ""

            }
        </div>
    )
}
   
