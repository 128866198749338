import React from 'react';
import {Link} from 'react-router-dom'
import Paginacion from '../../../componentes/Paginacion'
import obtenerDatos from '../../../librerias/obtenerDatos'
import Busqueda from '../../../componentes/Busqueda'
import Titulo from '../../../componentes/Titulo'
import API from '../../../librerias/api';
import funciones from '../../../librerias/funciones';
import obtenerPermisos from '../../../librerias/obtenerPermisos';
import icono from '../../img/icono-busqueda.png'; 
import swal from 'sweetalert'


const Trnjoyeriaenvios =() => {
    const TRNJOYERIAENVIOSURL= 'auth/packing/trnjoyeriasenvios'
    const URLTRNJOYERIAENVIOSELIMINAR = '/destroy'
    const { cargando,data:trnjoyeriaenvios, paginas,cambiarPagina, buscarData, current  } =obtenerDatos(TRNJOYERIAENVIOSURL);
    const permisos  =obtenerPermisos("modulo-tracking-metal");

    const eliminar = async (id)=>{
        // console.log(paginas.filter(page=>page.active)[0].page);      
        const respuesta = await  swal({
            title: "¿estas seguro?",
            text: "¿Deseas eliminar esté registro?",
            icon: "warning",
            // buttons: true,
            buttons: ["Cancelar", "Eliminar"],
            dangerMode: true,
          })
          if(respuesta){
              try {
                const response =  await API.delete(TRNJOYERIAENVIOSURL+'/'+id+URLTRNJOYERIAENVIOSELIMINAR);
                if(response.status===200){
                    swal("Categoria de envio elimnado correctamente", {
                        icon: "success",
                    });
                    cambiarPagina(paginas.filter(page=>page.active)[0].label);
                }

            } catch (error) {
                funciones.tratarerrores(error,document.getElementById("respuesta"));
            }

          }

    }

    return (
    <>
    <Titulo titulo="Envios de joyeria" tituloBajo=""></Titulo>


    <div className="row">
        <div className="col-xl-12">
            <div className="widget has-shadow">
                <div className="widget-header bordered no-actions d-flex align-items-center">
                    <h3 className="pl-3">Envios de joyeria</h3>
                    <Busqueda buscarData={buscarData} icono={icono} permiso={permisos} ruta="/agregartrnjoyeriaenvios" ></Busqueda>
                </div>
                <div className="pb-3">
                    <div className="table-responsive">
                    { cargando ?
                        <div className="text-center pt-5">
                            <h3 style={{color: "#C09E58"}}>Cargando...</h3>
                        </div>
                        :
                        <>
                        {
                            trnjoyeriaenvios.length >0 ?
                            <>
                            <table className="table mb-0 table-striped text-center">
                                <thead>
                                    <tr>
                                        <th scope="col">Poliza</th>
                                        <th scope="col">Concepto</th>
                                        <th scope="col">Fecha Creación</th>
                                        <th scope="col">Acciones</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                    trnjoyeriaenvios.map((object, index) => (
                                    <tr key={index}>
                                        <td>{ object.Poliza }</td>
                                        <td>{ object.Concepto }</td>
                                        <td>{ object.created_at }</td>
                                        <td>
                                            {
                                            permisos.Actualizar ===1  ?
                                            <Link className="" name="id" to={`/editar-trnjoyeriaenvios/${object.id}`}><i className="demo-icon icon-editar color-tecno">&#xe804;</i></Link>
                                            :
                                            ""
                                            }
                                            {
                                            permisos.Visualizar ===1  ?
                                            <Link className="ml-3" name="id" to={`/ver-trnjoyeriaenvios/${object.id}`}><i className="demo-icon icon-ver color-tecno">&#xe814;</i></Link>
                                            :
                                            ""
                                            }
                                            {
                                            permisos.Eliminar ===1  ?
                                            <Link className="ml-3" to="#" onClick={()=>eliminar(object.id)}><i className="demo-icon text-danger icon-eliminar">&#xe805;</i></Link> 
                                            :
                                            ""
                                            }
                                        </td>
                                    </tr>
                                    ))
                                    }
                                </tbody>
                            </table>
                            <Paginacion paginas={paginas} cambiarPagina={cambiarPagina} current={current}  ></Paginacion>
                            </>
                            :
                            <div className="text-center pt-5">
                                <h3>Sin resultados...</h3>
                            </div> 
                        }
                        </>
                    }
                    </div>
                </div>
                <div id="respuesta" ></div>
            </div>
        </div>
    </div>
    </>

    )
}

export default Trnjoyeriaenvios;