import React,{useState,useEffect} from 'react';
import Titulo from '../../../componentes/Titulo'
import API from '../../../librerias/api';
import history from '../../../librerias/history';
import { useForm } from 'react-hook-form'
import funciones from '../../../librerias/funciones'
import obtenerCatalogoSinDefecto from '../../../librerias/obtenerCatalogoSinDefecto'
import swal from 'sweetalert'
import {    Link  } from "react-router-dom";
import perfil from './../../Usuarios/perfil.png';
const Agregarpersonal =() => {
    const { register, handleSubmit, errors  } = useForm()
    const [catpersonal,setCatpersonal] = useState({})

    const onSubmit =async (data,e) => { 
        try {
            document.getElementById("enviar").disabled = true;
            let formData = new FormData(document.getElementById("formulario"));
            const response =  await API.post('auth/packing/personal-agregar', formData);
            if(response.status===201){
               swal({
                    title: "Registrado",
                    text: "Personal registrado correctamente",
                    icon: "success",
                    button: "Aceptar"
               }); 
               document.getElementById("enviar").disabled = false;
               history.push('/personal')
            }else{
                document.getElementById("enviar").disabled = false;
                funciones.tratarerrores(response,document.getElementById("respuesta"));
            }
          } catch (error) {
            funciones.tratarerrores(error,document.getElementById("respuesta"));
            document.getElementById("enviar").disabled = false;
          }
  
       }

    const traerCatpersonal =async () => {
        const catpersonal= await obtenerCatalogoSinDefecto('auth/packing/catpersonal-catalogo')
        if(catpersonal || catpersonal.length===0){
            catpersonal.unshift({value: "", label: '--Selecciona un valor--'})
            setCatpersonal(catpersonal);
        }else{
            setCatpersonal({value: "", label: '--Selecciona un valor--'})
        }
        
    }
    useEffect(
        ()=>{
            traerCatpersonal();
        }
    ,[]);
    const onChangeImg =  e => {
        // setValue("img", e.target.files[0]);
          // Creamos el objeto de la clase FileReader
        let reader = new FileReader();
        // Leemos el archivo subido y se lo pasamos a nuestro fileReader
        reader.readAsDataURL(e.target.files[0]);
        // Le decimos que cuando este listo ejecute el código interno
        reader.onload = ()=>{
            let img = document.getElementById('img-carga');
            img.src = reader.result;
        };
    }
    return (
    <>
    <Titulo titulo="Personal" tituloBajo="Personal&gt; Agregar Personal"></Titulo>
    <div className="row">
        <div className="col-xl-12">
            <div className="widget has-shadow">
                <div className="widget-header bordered no-actions d-flex align-items-center">
                    <h3 className="pl-3">Agregar Personal</h3>
                    {/* <Busqueda buscarData={buscarData} icono={icono} ></Busqueda> */}
                </div>
                <div className="widget-body" >
                    <form onSubmit={handleSubmit(onSubmit)} id="formulario">
                        <div className="form-group">
                            {/* <label for="name">Nombre*</label> */}
                            <div className="col-4 mx-auto text-center" >
                                <img src={perfil} id="img-carga" alt="Avatar" style={{"borderRadius":"50%","width":"230px;"}} /> 
                                <input type="file" className="form-control" onChange={onChangeImg} id="imgD" name="imgD" ref={register()} accept="image/png,image/jpeg"  />

                            </div>
                            {/* <input type="text" className="form-control" id="name" name="name" ref={register({ required: true })}  /> */}
                        </div>
                        <div className="form-group row">
                            <div className="col-6">
                                <label htmlFor="Nombre">Nombre*</label>
                                <input type="text" className="form-control" id="Nombre" name="Nombre" ref={register({ required: true })}  />
                                {errors.Nombre &&
                                <div className="error-form text-red p-small">
                                Este campo no puede estar vacío!
                                </div>
                                }
                            </div>
                            <div className="col-6">
                                <label htmlFor="Apellido">Apellidos*</label>
                                <input type="text" className="form-control" id="Apellido" name="Apellido" ref={register({ required: true })}  />
                                {errors.Apellido &&
                                <div className="error-form text-red p-small">
                                Este campo no puede estar vacío!
                                </div>
                                }
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="col-6">
                                <label htmlFor="catpersonal_id">Cat. Personal*</label>
                                <select className="form-control" name="catpersonal_id" id="catpersonal_id" ref={register({ required: true })} >
                                    {
                                        catpersonal.length >0 ?
                                        catpersonal.map((object, index) => (
                                            <option value={object.value}  key={index}>{object.label}</option>
                                        ))
                                        :
                                        ""
                                    }
                                </select>
                                {errors.catpersonal_id &&
                                <div className="error-form text-red p-small">
                                Este campo no puede estar vacío!
                                </div>
                                }
                            </div>
                            <div className="col-6" >
                                <label htmlFor="Numero">Número personal*</label>
                                <input type="text" className="form-control" id="Numero" name="Numero" ref={register({ required: true })}  />
                                {errors.Numero &&
                                <div className="error-form text-red p-small">
                                Este campo no puede estar vacío!
                                </div>
                                }
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="col-6">
                                <label htmlFor="Telefono" >Teléfono*</label>
                                <input type="text" className="form-control" id="Telefono" name="Telefono" ref={register({ required: true })}  />
                                {errors.Telefono &&
                                <div className="error-form text-red p-small">
                                Este campo no puede estar vacío!
                                </div>
                                }
                            </div>
                            <div className="col-6">
                                <label htmlFor="Telefono2" >Teléfono #2*</label>
                                <input type="text" className="form-control" id="Telefono2" name="Telefono2" ref={register}  />
                                {errors.Telefono2 &&
                                <div className="error-form text-red p-small">
                                Este campo no puede estar vacío!
                                </div>
                                }
                            </div>
                        </div>
                        <div className="form-group">
                            <label htmlFor="Sexo" >Sexo*</label>
                            <select className="form-control" id="Sexo" name="Sexo" rows="3" ref={register({ required: true })}>
                                <option value="" >--Selecciona un valor--</option>
                                <option value="H" >Hombre</option>
                                <option value="M" >Mujer</option>
                            </select>
                            {errors.Sexo &&
                            <div className="error-form text-red p-small">
                            Este campo no puede estar vacío!
                            </div>
                            }
                        </div>
                        <div id="respuesta" ></div>
                        <div class="text-right">
                            <Link to="/personal" className="btn btn-secondary">Atras</Link>
                            <button type="submit" id="enviar" className="btn btn-primary ml-2">Enviar</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
    </>

    )
}

export default Agregarpersonal;