
import React  , {useEffect,useState}  from "react";
import Login from "./paginas/Login";
import Cerrar from "./paginas/Cerrar";
import {
  Switch,
  Route
} from "react-router-dom";
import Header from './componentes/Header/Header';
import MenuLateral from './componentes/MenuLateral';
import Footer from './componentes/Footer/Footer';

import Roles from './paginas/Roles/Roles'
import AgregarRoles from './paginas/Roles/AgregarRoles'
import VerRol from './paginas/Roles/VerRol'
import EditarRoles from './paginas/Roles/EditarRoles'


import Permisos from './paginas/Permisos/Permisos'
import AgregarPermiso from './paginas/Permisos/AgregarPermisos'
import EditarPermisos from './paginas/Permisos/EditarPermisos'
import VerPermisos from './paginas/Permisos/VerPermisos'

import Usuarios from './paginas/Usuarios/Usuarios'
import AgregarUsuario from './paginas/Usuarios/AgregarUsuario'
import EditarUsuario from './paginas/Usuarios/EditarUsuario'
import VerUsuario from './paginas/Usuarios/VerUsuario'

import Catpesonal from './paginas/tracking/catpersonal/Catpesonal'
import Agregarcatpersonal from './paginas/tracking/catpersonal/Agregarcatpersonal'
import Editarcatpersonal from './paginas/tracking/catpersonal/Editarcatpersonal'
import Vercatpersonal from './paginas/tracking/catpersonal/Vercatpersonal'

import Catpaquete from './paginas/tracking/catpaquete/Catpaquete'
import Agregarcatpaquete from './paginas/tracking/catpaquete/Agregarcatpaquete'
import Editarcatpaquete from './paginas/tracking/catpaquete/Editarcatpaquete'
import Vercatpaquete from './paginas/tracking/catpaquete/Vercatpaquete'

import Catenvios from './paginas/tracking/catenvios/Catenvios'
import Agregarcatenvio from './paginas/tracking/catenvios/Agregarcatenvio'
import Editarcatenvio from './paginas/tracking/catenvios/Editarcatenvio'
import Vercatenvio from './paginas/tracking/catenvios/Vercatenvio'

import Catalmacenesvirtuales from './paginas/tracking/catalmacenesvirtuales/Catalmacenesvirtuales'
import Agregarcatalmacenesvirtuales from './paginas/tracking/catalmacenesvirtuales/Agregarcatalmacenesvirtuales'
import Editarcatalmacenesvirtuales from './paginas/tracking/catalmacenesvirtuales/Editarcatalmacenesvirtuales'
import Vercatalmacenesvirtuales from './paginas/tracking/catalmacenesvirtuales/Vercatalmacenesvirtuales'

import Catalmaceneslugar from './paginas/tracking/catalmaceneslugar/Catalmaceneslugar'
import Agregarcatalmaceneslugar from './paginas/tracking/catalmaceneslugar/Agregarcatalmaceneslugar'
import Editarcatalmaceneslugar from './paginas/tracking/catalmaceneslugar/Editarcatalmaceneslugar'
import Vercatalmaceneslugar from './paginas/tracking/catalmaceneslugar/Vercatalmaceneslugar'

import Personal from './paginas/tracking/personal/Personal'
import Agregarpersonal from './paginas/tracking/personal/Agregarpersonal'
import Editarpersonal from './paginas/tracking/personal/Editarpersonal'
import Verpersonal from './paginas/tracking/personal/Verpersonal'

import Trnmetalenvios from './paginas/tracking/trnmetalenvios/Trnmetalenvios'
import AgregarTrnmetalenvios from './paginas/tracking/trnmetalenvios/AgregarTrnmetalenvios'
import Editartrnenviometal from './paginas/tracking/trnmetalenvios/Editartrnenviometal'
import Vertrnenviometal from './paginas/tracking/trnmetalenvios/Vertrnenviometal'

import Trnjoyeriaenvios from './paginas/tracking/trnjoyeriasenvios/Trnjoyeriaenvios'
import Agregarjoyeriaenvios from './paginas/tracking/trnjoyeriasenvios/Agregarjoyeriaenvios'
import Editartrnenviojoyeria from './paginas/tracking/trnjoyeriasenvios/Editartrnenviojoyeria'
import Vertrnjoyeriametal from './paginas/tracking/trnjoyeriasenvios/Vertrnjoyeriametal'

import Visualizartracking from './paginas/tracking/visualizartracking/Visualizartracking'

import Bienvenido from './paginas/Bienvenido'


// import AgregarPermiso from './paginas/Permisos/AgregarPermiso'

const App =()=>{
  // localStorage.clear();
  const [usuario,setUsuario] = useState(false);
  useEffect(() => { 
    if(localStorage.getItem('user')){
      const user = JSON.parse(localStorage.getItem('user'));
      var ims = Date.parse(user.expires_at);
      var fecha = new Date(ims);
       var fechaActualIms = Date.now();
       var fechaActual = new Date(fechaActualIms);
       if(fechaActual<=fecha){
        setUsuario(true);
       }else{
        setUsuario(false);
        localStorage.clear();
       }
    }
    },[]);

  return (

      usuario===false && localStorage.getItem('user')==null ?
      <Login cambiarUsuario={ ()=>{setUsuario(true);;  }} />
      :
      <>
      <div className="page" >
      <Header /> 
      <div className="page-content d-flex align-items-stretch" >
        <MenuLateral />
        <div className="content-inner">
        <div className="container-fluid">
          <Switch>
              <Route path="/salir">
                <Cerrar></Cerrar>
              </Route>
              <Route path="/roles">
                <Roles></Roles>
              </Route>
              <Route path="/AgregarRoles">
                <AgregarRoles></AgregarRoles>
              </Route>
              <Route exact path='/editar-rol/:id?' component={EditarRoles}/>  
              <Route exact path='/ver-rol/:id?' component={VerRol}/>  
              <Route path="/usuarios">
                <Usuarios></Usuarios>
              </Route>
              <Route path="/AgregarUsuario">
                <AgregarUsuario></AgregarUsuario>
              </Route>
              <Route exact path='/editar-usuario/:id?' component={EditarUsuario}/>  
              <Route exact path='/ver-usuario/:id?' component={VerUsuario}/>
              <Route path="/permisos">
                <Permisos></Permisos>
              </Route>
              <Route path="/AgregarPermisos">
                <AgregarPermiso></AgregarPermiso>
              </Route>
              <Route exact path='/editar-permiso/:id?' component={EditarPermisos}/>  
              <Route exact path='/ver-permiso/:id?' component={VerPermisos}/>
              <Route exact path='/' component={Bienvenido}/>
              <Route path="/catpersonal">
                <Catpesonal></Catpesonal>
              </Route>
              <Route path="/agregarcatpersonal">
                <Agregarcatpersonal></Agregarcatpersonal>
              </Route>
              <Route exact path='/editar-catpersonal/:id?' component={Editarcatpersonal}/> 
              <Route exact path='/ver-catpersonal/:id?' component={Vercatpersonal}/> 
              <Route path="/catpaquetes">
                <Catpaquete></Catpaquete>
              </Route>
              <Route path="/agregarcatpaquete">
                <Agregarcatpaquete></Agregarcatpaquete>
              </Route>
              <Route exact path='/editar-catpaquete/:id?' component={Editarcatpaquete}/>  
              <Route exact path='/ver-catpaquete/:id?' component={Vercatpaquete}/>  
              <Route path="/catenvios">
                <Catenvios></Catenvios>
              </Route>
              <Route path="/agregarcatenvio">
                <Agregarcatenvio></Agregarcatenvio>
              </Route>
              <Route exact path='/editar-catenvio/:id?' component={Editarcatenvio}/> 
              <Route exact path='/ver-catenvio/:id?' component={Vercatenvio}/> 
              <Route path="/catalmacenesvirtuales">
                <Catalmacenesvirtuales></Catalmacenesvirtuales>
              </Route>
              <Route path="/agregarcatalmacenesvirtuales">
                <Agregarcatalmacenesvirtuales></Agregarcatalmacenesvirtuales>
              </Route>
              <Route exact path='/editar-catalmacenesvirtuales/:id?' component={Editarcatalmacenesvirtuales}/> 
              <Route exact path='/ver-catalmacenesvirtuales/:id?' component={Vercatalmacenesvirtuales}/> 
              <Route path="/catalmaceneslugar">
                <Catalmaceneslugar></Catalmaceneslugar>
              </Route>
              <Route path="/agregarcatalmaceneslugar">
                <Agregarcatalmaceneslugar></Agregarcatalmaceneslugar>
              </Route>
              <Route exact path='/editar-catalmaceneslugar/:id?' component={Editarcatalmaceneslugar}/> 
              <Route exact path='/ver-catalmaceneslugar/:id?' component={Vercatalmaceneslugar}/> 
              <Route path="/personal">
                <Personal></Personal>
              </Route>
              <Route path="/agregarpersonal">
                <Agregarpersonal></Agregarpersonal>
              </Route>
              <Route exact path='/editar-personal/:id?' component={Editarpersonal}/> 
              <Route exact path='/ver-personal/:id?' component={Verpersonal}/> 
              <Route path="/trackingmetal">
                <Trnmetalenvios></Trnmetalenvios>
              </Route>
              <Route path="/agregarTrnmetalenvios">
                <AgregarTrnmetalenvios></AgregarTrnmetalenvios>
              </Route>
              <Route exact path='/editar-trnmetalenvios/:id?' component={Editartrnenviometal}/> 
              <Route exact path='/ver-trnmetalenvios/:id?' component={Vertrnenviometal}/> 
              <Route path="/trackingjoyeria">
                <Trnjoyeriaenvios></Trnjoyeriaenvios>
              </Route>
              <Route path="/agregartrnjoyeriaenvios">
                <Agregarjoyeriaenvios></Agregarjoyeriaenvios>
              </Route>
              <Route exact path='/editar-trnjoyeriaenvios/:id?' component={Editartrnenviojoyeria}/> 
              <Route exact path='/ver-trnjoyeriaenvios/:id?' component={Vertrnjoyeriametal}/> 
              <Route path="/reportetracking">
                <Visualizartracking></Visualizartracking>
              </Route>
          </Switch>
          
        </div>
        
          <Footer />
        </div>
      </div>
      </div>
      </>
  );
}

export default App;
