import React,{useState,useEffect} from 'react';
import Titulo from '../../../componentes/Titulo'
import API from '../../../librerias/api';
import history from '../../../librerias/history';
import { useForm } from 'react-hook-form'
import funciones from '../../../librerias/funciones'
import swal from 'sweetalert'
import {    Link  } from "react-router-dom";
import ModalTablaPersonal from '../../../componentes/ModalTablaPersonal'
import obtenerCatalogoSinDefecto from '../../../librerias/obtenerCatalogoSinDefecto'

const Agregarjoyeriaenvios =() => {
    const { register, handleSubmit, errors  } = useForm()
    const [catpaquete,setCatpaquete] = useState({})
    const [catenvio,setCatenvio] = useState({})
    const [catalmaceneslo,setCatalmaceneslo] = useState({})
    const [personal,setPersonal] = useState({id:null,nombre:null});
    const [personalR,setPersonalR] = useState({id:null,nombre:null});
    const [serviciohecho,setServiciohecho] = useState(null)

    const onSubmit =async (data,e) => { 
        try {
            document.getElementById("enviar").disabled = true;
            let formData = new FormData(document.getElementById("formulario"));
            formData.append("personalo_id",personal.id);
            formData.append("personalr_id",personalR.id);
            const response =  await API.post('auth/packing/trnjoyeriasenvios-agregar', formData);
            if(response.status===201){
                swal({
                    title: "Registrado",
                    text: "Envío de joyeria realizado correctamente",
                    icon: "success",
                    button: "Aceptar"
                }); 
                setServiciohecho(response.data)
               document.getElementById("enviar").disabled = false;
            }else{
                document.getElementById("enviar").disabled = false;
                funciones.tratarerrores(response,document.getElementById("respuesta"));
            }
          } catch (error) {
            funciones.tratarerrores(error,document.getElementById("respuesta"));
            if(document.getElementById("enviar"))
            document.getElementById("enviar").disabled = false;
          }
  
       }
    
    const traerCatpaquete =async () => {
        const catpaquete= await obtenerCatalogoSinDefecto('auth/packing/catpaquete-catalogo')
        if(catpaquete || catpaquete.length===0){
            catpaquete.unshift({value: "", label: '--Selecciona un valor--'})
            setCatpaquete(catpaquete);
        }else{
            setCatpaquete({value: "", label: '--Selecciona un valor--'})
        }
        
    }
    const traerCatenvios =async () => {
        const catenvio= await obtenerCatalogoSinDefecto('auth/packing/catenvios-catalogo')
        if(catenvio || catenvio.length===0){
            catenvio.unshift({value: "", label: '--Selecciona un valor--'})
            setCatenvio(catenvio);
        }else{
            setCatenvio({value: "", label: '--Selecciona un valor--'})
        }
        
    }
    const traerCatalmaceneslo =async () => {
        const catalmaceneslo= await obtenerCatalogoSinDefecto('auth/packing/catalmaceneslugar-catalogo')
        if(catalmaceneslo || catalmaceneslo.length===0){
            catalmaceneslo.unshift({value: "", label: '--Selecciona un valor--'})
            setCatalmaceneslo(catalmaceneslo);
        }else{
            setCatalmaceneslo({value: "", label: '--Selecciona un valor--'})
        }
        
    }
    const imprimirTicket= () =>{
        try {
            // console.log(response)
                var contenidoHtml ="<div style='width:100%;  text-align: center;font-family: monospace;' >";
                contenidoHtml +=    serviciohecho.qrFoioO;
                contenidoHtml += "</div>";
                var ticket = document.createElement("div");
                ticket.innerHTML =contenidoHtml;
                var ventimp = window.open(' ', 'folioCreacion1');
                ventimp.document.write( ticket.innerHTML );
                ventimp.document.querySelector("body").style.margin = "0px";
                ventimp.document.close();
                ventimp.print( );
                ventimp.close( );
                
            

        } catch (error) {
            funciones.tratarerrores(error,document.getElementById("respuesta"),error);
            console.log(error)
        }
    }
    useEffect(
        ()=>{
            traerCatpaquete();
            traerCatenvios();
            traerCatalmaceneslo();
        }
    ,[]);
    return (
    <>
    <Titulo titulo="Envios de joyeria" tituloBajo="Envios de joyeria&gt; Agregar envío de metal"></Titulo>
    <div className="row">
        <div className="col-xl-12">
            <div className="widget has-shadow">
                <div className="widget-header bordered no-actions d-flex align-items-center">
                    <h3 className="pl-3">Envios de joyeria</h3>
                    {/* <Busqueda buscarData={buscarData} icono={icono} ></Busqueda> */}
                </div>
                <div className="widget-body" >
                    {serviciohecho ?
                    <>
                    <div className="row" >
                        <div className="col-12" >
                            <h1 className="text-center" >Qr para seguimiento de huella</h1>
                        </div>
                        <div className="col-12 col-sm-4" >

                        </div>
                        <div className="col-12 col-sm-4" >
                            <h3 className="text-center">QR punto A</h3>
                            <div className="text-center" >{serviciohecho.folioO}</div>
                            <div className="text-center qr-contenedor" style={{width:"100%"}}  dangerouslySetInnerHTML={{__html: serviciohecho.qrFoioO}} />
                        </div>
                        <div className="col-12 col-sm-4" >

                        </div>
                    </div>
                    <Link to="/trackingjoyeria" className="btn btn-secondary p-0 mt-2" style={{width:"100%",fontSize:"20px"}}>Atras</Link>
                    <button className="btn btn-primary p-0 mt-2" style={{width:"100%"}} onClick={imprimirTicket} >Imprimir ticket</button>
                    </>
                    :
                    <form onSubmit={handleSubmit(onSubmit)} id="formulario">
                        <div className="form-group row">
                            <div className="col-6" >
                                <label for="Concepto">Concepto*</label>
                                <input type="text" className="form-control" id="Concepto" name="Concepto" ref={register({ required: true })}  />
                                {errors.Concepto &&
                                <div className="error-form text-red p-small">
                                Este campo no puede estar vacío!
                                </div>
                                }
                            </div>
                            <div className="col-6" >
                                <label htmlFor="catpaquete_id">Cat. Paquete*</label>
                                <select className="form-control" name="catpaquete_id" id="catpaquete_id" ref={register({ required: true })} >
                                    {
                                        catpaquete.length >0 ?
                                        catpaquete.map((object, index) => (
                                            <option value={object.value}  key={index}>{object.label}</option>
                                        ))
                                        :
                                        ""
                                    }
                                </select>
                                {errors.catpaquete_id &&
                                <div className="error-form text-red p-small">
                                Este campo no puede estar vacío!
                                </div>
                                }
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="col-6" >
                                <label for="rol">Poliza/Número*</label>
                                <input type="text" className="form-control" id="Poliza" name="Poliza" ref={register({ required: true })}  />
                                {errors.Poliza &&
                                <div className="error-form text-red p-small">
                                Este campo no puede estar vacío!
                                </div>
                                }
                            </div>
                            <div className="col-6" >
                                <label for="rol">CT*</label>
                                <input type="text" className="form-control" id="CT" name="CT" ref={register({ pattern: /^\d*(\.\d{1})?\d{0,4}$/i})}  />
                                {errors.CT &&
                                <div className="error-form text-red p-small">
                                Este campo necesita ser un número! !
                                </div>
                                }
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="col-6" >
                                <label for="Medida">Medida*</label>
                                <input type="text" className="form-control" id="Medida" name="Medida" ref={register({ pattern: /^\d*(\.\d{1})?\d{0,4}$/i})}  />
                                {errors.Medida &&
                                <div className="error-form text-red p-small">
                                Este campo no necesita ser un número!!
                                </div>
                                }
                            </div>
                            <div className="col-6" >
                                <label for="rol">Costo - xCT*</label>
                                <input type="text" className="form-control" id="CostoxCT" name="CostoxCT" ref={register({ pattern: /^\d*(\.\d{1})?\d{0,4}$/i})}  />
                                {errors.CostoxCT &&
                                <div className="error-form text-red p-small">
                                Este campo no puede estar vacío!
                                </div>
                                }
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="col-6" >
                                <label for="rol">Calidad*</label>
                                <input type="text" className="form-control" id="Calidad" name="Calidad" ref={register}  />
                                {errors.Calidad &&
                                <div className="error-form text-red p-small">
                                Este campo no necesita ser un número!!
                                </div>
                                }
                            </div>
                            <div className="col-6" >
                                <label for="rol">Tipo diamante*</label>
                                <input type="text" className="form-control" id="TipoDiamante" name="TipoDiamante" ref={register} />
                                {errors.TipoDiamante &&
                                <div className="error-form text-red p-small">
                                Este campo no puede estar vacío!
                                </div>
                                }
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="col-6" >
                                <label almacen_id="rol">Almacen destino*</label>
                                <select className="form-control" name="almacen_id" id="almacen_id"  ref={register({ required: true })} >
                                    {
                                        catalmaceneslo.length >0 ?
                                        catalmaceneslo.map((object, index) => (
                                            <option value={object.value}  key={index}>{object.label}</option>
                                        ))
                                        :
                                        ""
                                    }
                                </select>
                            </div>
                            <div className="col-6" >
                                <label for="Destino">Descipción destino</label>
                                <input type="text" className="form-control" id="Destino" name="Destino" ref={register}  />
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="col-6" >
                                <label for="rol">Nota</label>
                                <input type="text" className="form-control" id="Nota" name="Nota" ref={register}  />
                            </div>
                        </div>
                        <h3 >Punto A</h3>
                        <div className="form-group row">
                            <div className="col-6" >
                                <label htmlFor="catalmaceneslugaro_id">Almacen origen*</label>
                                <select className="form-control" name="catalmaceneslugaro_id" id="catalmaceneslugaro_id"  ref={register({ required: true })} >
                                    {
                                        catalmaceneslo.length >0 ?
                                        catalmaceneslo.map((object, index) => (
                                            <option value={object.value}  key={index}>{object.label}</option>
                                        ))
                                        :
                                        ""
                                    }
                                </select>
                                {errors.catalmaceneslugaro_id &&
                                <div className="error-form text-red p-small">
                                Este campo no puede estar vacío!
                                </div>
                                }
                            </div>
                            <div className="col-6" >
                                <label htmlFor="catenvio_id">Tipo de Envío*</label>
                                <select className="form-control" name="catenvio_id" id="catenvio_id"  ref={register({ required: true })}>
                                    {
                                        catenvio.length >0 ?
                                        catenvio.map((object, index) => (
                                            <option value={object.value}  key={index}>{object.label}</option>
                                        ))
                                        :
                                        ""
                                    }
                                </select>
                                {errors.catenvio_id &&
                                <div className="error-form text-red p-small">
                                Este campo no puede estar vacío!
                                </div>
                                }
                            </div> 
                        </div>
                        <div className="form-group row">
                            <div className="col-6" >
                                <label htmlFor="personal_emtrega">Personal entrega*</label>
                                <div className="input-group">
                                    <input type="text" className="form-control" name="Personal" id="Personal" readOnly={true} value={personal.nombre}   ref={register({ required: true })} />
                                    <div className="input-group-append">
                                        <button className="btn btn-outline-secondary" data-toggle="modal" data-target="#exampleModalPersonal" type="button">Buscar</button>
                                    </div>
                                </div>
                                {errors.Personal &&
                                <div className="error-form text-red p-small">
                                Este campo no puede estar vacío!
                                </div>
                                }
                            </div>
                            <div className="col-6" >
                                <label htmlFor="personal_emtrega">Personal Responsable*</label>
                                <div className="input-group">
                                    <input type="text" className="form-control" name="PersonalR" id="PersonalR" readOnly={true} value={personalR.nombre}   ref={register({ required: true })} />
                                    <div className="input-group-append">
                                        <button className="btn btn-outline-secondary" data-toggle="modal" data-target="#exampleModalPersonalD" type="button">Buscar</button>
                                    </div>
                                </div>
                                {errors.personalR &&
                                <div className="error-form text-red p-small">
                                Este campo no puede estar vacío!
                                </div>
                                }
                            </div>

                        </div>
                        <div className="form-group row">
                            <div className="col-6" >
                                <label for="Nota">Tipo*</label>
                                <select name="tipo" id="tipo"  ref={register({ required: true })} className="form-control">
                                    <option value="E">Entrada</option>
                                    <option value="S">Salida</option>
                                </select>
                            </div>
                            <div className="col-6" >
                                <label for="Nota">Nota*</label>
                                <input type="text" className="form-control" id="NotaD" name="NotaD" ref={register}  />
                            </div>
                        </div>
                        <div id="respuesta" ></div>
                        <div class="text-right">
                            <Link to="/trackingjoyeria" className="btn btn-secondary">Atras</Link>
                            <button type="submit" id="enviar" className="btn btn-primary ml-2">Enviar</button>
                        </div>
                    </form>
                    }
                </div>
            </div>
        </div>
    </div>
    <ModalTablaPersonal titulo="Personal" urlBusqueda="auth/packing/personal-sinpaginacion" elegirElemento={setPersonal} idModal="exampleModalPersonal" />
    <ModalTablaPersonal titulo="Personal" urlBusqueda="auth/packing/personal-sinpaginacion" elegirElemento={setPersonalR} idModal="exampleModalPersonalD" />
    </>
    )
}

export default Agregarjoyeriaenvios;