import {useState, useEffect} from 'react'
// import funciones from './../funciones'
import API from './../librerias/api'


export default ()  =>{
    const [cargando, setCargando] = useState(true)
    const [data, setData] = useState([])
    useEffect(()=>{
        const traerData = async () =>{
            try {
                const response =   await API.post('auth/permisosModulo',null)
                if(response.status===200){

                    const data = await response.data
                    setData(data);
                    setCargando(false);
                }else{
                    setData([]);
                    setCargando(false);

                }
            } catch (error) {
                console.log(error);
                setData([]);
            }


       
        }
        traerData();
    },[])
    return {cargando,data}
}