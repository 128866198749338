import React, {useEffect,useState} from 'react';
import {Link} from 'react-router-dom'
import Titulo from '../../../componentes/Titulo'
import API from '../../../librerias/api';
import funciones from '../../../librerias/funciones';
import obtenerPermisos from '../../../librerias/obtenerPermisos';
import history from '../../../librerias/history';
import swal from 'sweetalert'
import { useForm } from 'react-hook-form'

const Catpesonal =() => {
    const { register, handleSubmit, errors  } = useForm()
    // const CATPERSONALURL= 'auth/packing/catpersonal'
    // const URLCATPERSONALELIMINAR = '/destroy'
    const [botones,setBotones] = useState([])
    const [registros,setRegistros] = useState([])
    const [encabezado,setEncabezado] = useState(null)

    const onSubmit =async (data,e) => { 
        try {
            document.getElementById("enviar").disabled = true;
            const response =  await API.post('auth/packing/trackingbusqueda', data);
            if(response.status===200){
                setBotones(response.data)
               document.getElementById("enviar").disabled = false;
            }else{
                console.log("error")
                document.getElementById("enviar").disabled = false;
                funciones.tratarerrores(response,document.getElementById("respuesta"));
            }
          } catch (error) {
            console.log(error)
            document.getElementById("enviar").disabled = false;
            funciones.tratarerrores(error,document.getElementById("respuesta"));
            // document.getElementById("enviar").disabled = false;
          }
  
       }
    const onclickCargaEnvios =async (object)=>{
        try {
            let formData = new FormData();
            formData.append("poliza",object.Poliza);
            formData.append("tipo",object.tipoBusqueda);
            const response =  await API.post('auth/packing/trackingbusquedadetalles', formData);
            if(response.status===200){
                setBotones([])
                setRegistros(response.data)
               document.getElementById("enviar").disabled = false;
            }else{
                console.log("error")
                document.getElementById("enviar").disabled = false;
                funciones.tratarerrores(response,document.getElementById("respuesta"));
            }
          } catch (error) {
            console.log(error)
            document.getElementById("enviar").disabled = false;
            funciones.tratarerrores(error,document.getElementById("respuesta"));
            // document.getElementById("enviar").disabled = false;
          }
    }
    return (
    <>
    <Titulo titulo="Revisión tracking" tituloBajo=""></Titulo>

    <div className="row">
        <div className="col-xl-12">
            <div className="widget has-shadow p-5">
                <form  onSubmit={handleSubmit(onSubmit)}>
                    <div className="input-group mb-3">
                        <input type="text" className="form-control mr-3" placeholder="Buscar Folio / Póliza" name="busqueda" id="busqueda" aria-label="Buscar folio/Póliza" aria-describedby="basic-addon2" ref={register({ required: true })} />
                        <div className="input-group-append">
                            <button className="btn btn-outline-secondary" id="enviar" type="submit">Buscar</button>
                        </div>
                    </div>
                    {errors.busqueda &&
                        <div className="mb-2 error-form text-red p-small">
                        ¡Este campo no puede estar vacío!
                        </div>
                    }
                     <b  style={{color: "#a98a49"}}>Selecciona el tipo de envío: </b> 
                    <div className="row mt-2 mb-3 ml-1" >
                    <div class="form-check form-check-inline">
                           
                            <input class="form-check-input" type="radio" name="tipo" id="tipo1" value="M" ref={register({ required: true })}  />
                            <label class="form-check-label" for="tipo1">
                                Metal
                            </label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" name="tipo" id="tipo2" value="J" ref={register({ required: true })}  />
                            <label class="form-check-label" for="tipo2">
                                Joyeria
                            </label>
                        </div>                
                    {errors.tipo &&
                        <div className="error-form text-red p-small">
                        ¡Seleccciona el tipo de tracking!
                        </div>
                    }
                        <div id="respuesta" ></div>
                    </div>
                </form>
                <div className="row" >
                    {
                        botones.length>0?
                        <>
                        <div className="col-12">
                            <b  style={{color: "#a98a49"}}>Selecciona el tipo de envío: </b> 
                        </div>
                        {
                            botones.map((objecto, index)=>(
                                <div className="col-2" key={index} >
                                  <button className="btn btn-primary" onClick={()=>{onclickCargaEnvios(objecto)}} >{objecto.Poliza}</button>
                                </div>
                            ))

                        }
                        </>
                        :
                        ""
                    }
                </div>
                
                    {
                        registros.length>0?
                        registros.map((objecto, index)=>(
                        <div>
                        {
                            objecto.detalles.map((objectdetalle, index)=>(
                                
                            <div className="row border pl-5" key={index}>
                                
                                <div className="row iconocheck col-12 justify-content-start">
                                    {
                                        objectdetalle.huellar==1?
                                        <i class="icono fas fa-check  justify-content-center"></i>
                                        :
                                        <i class="icono fa fa-circle justify-content-center" aria-hidden="true"></i>
                                    }
                                    <div className="linea"></div>
                                    <i class="iconoflecha fas fa-arrow-down  justify-content-center"></i>
                                </div>
                                <div className="col-4 border2 pb-2 pt-2">
                                    <b>Folio: </b> {objectdetalle.folioo}
                                </div>
                                <div className="col-4 border2 pb-2 pt-2">
                                 <b>Entrega: </b>{objectdetalle.personalorigen.Nombre+" "+objectdetalle.personalorigen.Apellido} 
                                </div>
                                <div className="col-4 border2 pb-2 pt-2">
                                  <b>Responsable: </b> {objectdetalle.personalresponsable.Nombre+" "+objectdetalle.personalresponsable.Apellido} 
                                </div>
                                <div className="col-4 border2 pb-2 pt-2">
                                  <b>Almacén: </b>{objectdetalle.almacenorigen.Nombre} 
                                </div> 
                                <div className="col-4 border2 pb-2 pt-2">
                                    <b>Tipo: </b>{objectdetalle.tipo} 
                                </div>
                                <div className="col-4 border2 pb-2 pt-2">
                                    <b>Envío: </b>{objectdetalle.enviotipo.Nombre} 
                                </div>
                                <div className="col-4 border2 pb-2 pt-2">
                                    <b>Fecha: </b>{objectdetalle.created_at} 
                                </div>
                                <div className="col-4 border2 pb-2 pt-2">
                                    <b>Nota
                                    {
                                    objectdetalle.huellar==1?
                                     <span>(Huella registrada)</span>
                                    :
                                        <span>(Huella sin registrar)</span>
                                    }   
                                    : 

                                    </b>
                                    
                                    
                                    {objectdetalle.nota} 
                                </div>
                                <button className="btn btn-outline-secondary mt-3 ml-2 mb-3" type="button" data-toggle="modal" data-target="#modalEncabezado" onClick={()=>{setEncabezado(objecto)}}>Ver mas</button>
                            </div>
                            ))     

                        }
                        </div>  

                        ))
                        :
                        ""
                    }
            </div>
        </div>
    </div>
    <div class="modal fade" id="modalEncabezado" tabindex="-1" role="dialog" aria-labelledby="modalEncabezadoTitle" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
            <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="modalEncabezadoTitle">
                <b>Información de envío número: </b>
                {
                   encabezado?
                   encabezado.Poliza
                   :
                   ""
                }
                </h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <form>
                <div class="modal-body">
                    {
                       encabezado===null?
                       "Sin encabezado seleccionado"
                       :
                       encabezado.detalles[0].trnmetalenvios_id?
                       <div className="row" >
                            <div className="col-6" >
                                <label >Nombre</label>
                                <label className="form-control" >{encabezado.Nombre}</label>
                            </div>
                            <div className="col-6" >
                                <label >Paquete</label>
                                <label className="form-control" >{encabezado.paquete.Nombre}</label>
                            </div>
                            <div className="col-6" >
                                <label >Kilataje</label>
                                <label className="form-control" >{encabezado.Kilataje}</label>
                            </div>
                            <div className="col-6" >
                                <label >PCB</label>
                                <label className="form-control" >{encabezado.PCB}</label>
                            </div>
                            <div className="col-6" >
                                <label >PSB</label>
                                <label className="form-control" >{encabezado.PSB}</label>
                            </div>
                            <div className="col-6" >
                                <label >Almacén</label>
                                <label className="form-control" >{encabezado.almacen.Nombre}</label>
                            </div>
                            <div className="col-6" >
                                <label >Destino</label>
                                <label className="form-control" >{encabezado.Destino}</label>
                            </div>
                            <div className="col-6" >
                                <label >Nota</label>
                                <label className="form-control" >{encabezado.Nota}</label>
                            </div>
                        </div>
                       :
                       <div className="row" >
                            <div className="col-6" >
                                <label >Concepto</label>
                                <label className="form-control" >{encabezado.Concepto}</label>
                            </div>
                            <div className="col-6" >
                                <label >Paquete</label>
                                <label className="form-control" >{encabezado.paquete.Nombre}</label>
                            </div>
                            <div className="col-6" >
                                <label >CT</label>
                                <label className="form-control" >{encabezado.CT}</label>
                            </div>
                            <div className="col-6" >
                                <label >Medida</label>
                                <label className="form-control" >{encabezado.Medida}</label>
                            </div>
                            <div className="col-6" >
                                <label >CostoxCT</label>
                                <label className="form-control" >{encabezado.CostoxCT}</label>
                            </div>
                            <div className="col-6" >
                                <label >Calidad</label>
                                <label className="form-control" >{encabezado.Calidad}</label>
                            </div>
                            <div className="col-6" >
                                <label >TipoDiamante</label>
                                <label className="form-control" >{encabezado.TipoDiamante}</label>
                            </div>
                            <div className="col-6" >
                                <label >Almacen</label>
                                <label className="form-control" >{encabezado.almacen.Nombre}</label>
                            </div>
                            <div className="col-6" >
                                <label >Destino</label>
                                <label className="form-control" >{encabezado.Destino}</label>
                            </div>
                            <div className="col-6" >
                                <label >Nota</label>
                                <label className="form-control" >{encabezado.Nota}</label>
                            </div>
                        </div>
                    }
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-outline-secondary" id="cerrar-mocal-estatus" data-dismiss="modal">Cerrar</button>
                </div>
            </form>
            </div>
        </div>
    </div>
    </>
    )
}

export default Catpesonal;