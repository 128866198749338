import React from 'react';


const Titulo =({titulo, tituloBajo}) => {
    return (
    <div className="row">
        <div className="page-header">
            <div className="d-flex align-items-center">
                <h2 className="page-header-title">{ titulo }</h2>
            </div>
            <span style={{fontSize:"15px",color:"#C09E58"}} > {tituloBajo}</span>
        </div>
    </div>
    )
}
export default Titulo;