import React, {useEffect} from 'react';
import Titulo from '../../../componentes/Titulo'
import { useForm } from 'react-hook-form'
import {    useParams} from "react-router-dom";
import obtenerData from '../../../librerias/obtenerData'
import {    Link  } from "react-router-dom";
import perfil from './../../Usuarios/perfil.png';
const Verpersonal =() => {
    const URLPERSONALLEER = 'auth/packing/personal-mostrar/'
    let { id } = useParams();
    const personal  =obtenerData(URLPERSONALLEER,id,1)
    const { register, handleSubmit, errors,setValue  } = useForm()


    useEffect(
        ()=>{
        const asignar =(personal) =>{
            if(personal.id){
                setValue('Nombre', personal.Nombre)
                setValue('Telefono', personal.Telefono)
                setValue('Telefono2', personal.Telefono2)
                setValue('Sexo', personal.Sexo)
                setValue('Numero', personal.Numero)
                document.getElementById("img-carga").src=personal.img;
            }
            }
        asignar(personal)
        }
    ,[setValue,personal]);
    const errorImg = e =>{
        e.target.src=perfil;
    }
    return (
        <>
        <Titulo titulo="Personal" tituloBajo="Personal &gt; Editar"></Titulo>
        <div className="row">
            <div className="col-xl-12">
                <div className="widget has-shadow">
                    <div className="widget-header bordered no-actions d-flex align-items-center">
                        <h3 className="pl-3">Personal</h3>
                        {/* <Busqueda buscarData={buscarData} icono={icono} ></Busqueda> */}
                    </div>
                    <div className="widget-body" >
                        <form>
                        <div className="form-group">
                            <div className="col-4 mx-auto text-center" >
                                <img src=""  onError={errorImg} id="img-carga" alt="Avatar" style={{"borderRadius":"50%","width":"230px;"}} /> 
                            </div>
                        </div>
                        <div className="form-group">
                                <label htmlFor="rol">Nombre*</label>
                                <input type="text" className="form-control" id="Nombre" name="Nombre" ref={register({ required: true })}  />
                                {errors.Nombre &&
                                <div className="error-form text-red p-small">
                                Este campo no puede estar vacío!
                                </div>
                                }
                        </div>
                        <div className="form-group row">
                            <div className="col-6" >
                                <label htmlFor="catpersonal_id">Cat. Personal*</label>
                                <label className="form-control">
                                    {
                                    personal.catpersonal ?
                                    personal.catpersonal.Nombre
                                    :
                                    ""
                                }
                                </label>
                            </div>
                            <div className="col-6" >
                                <label htmlFor="Numero">Número personal*</label>
                                <input type="text" className="form-control" id="Numero" name="Numero" ref={register({ required: true })}  />
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="col-6">
                                <label htmlFor="Telefono" >Teléfono*</label>
                                <input type="text" className="form-control" id="Telefono" name="Telefono" ref={register({ required: true })}  />
                                {errors.Telefono &&
                                <div className="error-form text-red p-small">
                                Este campo no puede estar vacío!
                                </div>
                                }
                            </div>
                            <div className="col-6">
                                <label htmlFor="Telefono2" >Teléfono #2*</label>
                                <input type="text" className="form-control" id="Telefono2" name="Telefono2" ref={register}  />
                                {errors.Telefono2 &&
                                <div className="error-form text-red p-small">
                                Este campo no puede estar vacío!
                                </div>
                                }
                            </div>
                        </div>
                        <div className="form-group">
                            <label htmlFor="Sexo" >Sexo*</label>
                            <select className="form-control" id="Sexo" name="Sexo" rows="3"  ref={register({ required: true })}>
                                <option value="" >--Selecciona un valor--</option>
                                <option value="H" >Hombre</option>
                                <option value="M" >Mujer</option>
                            </select>
                            {errors.Sexo &&
                            <div className="error-form text-red p-small">
                            Este campo no puede estar vacío!
                            </div>
                            }
                        </div>
                        <div id="respuesta" ></div>
                        <div className="text-right">
                            <Link to="/personal" className="btn btn-secondary">Atras</Link>
                        </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}

export default Verpersonal;