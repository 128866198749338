import {useState, useEffect} from 'react'
import funciones from './funciones'
import API from './api'


export default (URL)  =>{
    const [cargando, setCargando] = useState(true)
    const [data, setData] = useState([])
    const [paginas, setPaginas] = useState([],)
    const [current, setCurrent] = useState()
    const [pagina, setPagina] = useState({ page:1, busqueda:'' })

    useEffect(()=>{
        const traerData = async () =>{
            try {
                const response =   await API.post(URL,funciones.getFormData(pagina))
                if(response.status===200){
                    const data = await response.data
                    setData(data.data);
                    setPaginas(data.links);
                    setCurrent({"current_page": data.current_page, "last_page": data.last_page})
                    setCargando(false);
                }else{
                    setData([]);
                    setPaginas([],{});
                    setCargando(false);
                    // alert("2")

                }
            } catch (error) {
                setData([]);
                setPaginas([]);
                setCargando(false);
                funciones.tratarerrores(error,document.getElementById("respuesta"));
            }


       
        }
        traerData();
    },[pagina,URL])
    const cambiarPagina=(page)=>{
        setPagina({ ...pagina,page })
    }
    const buscarData = (palabra) => {
        setPagina({ page:1,busqueda : palabra  })
    }
    return {cargando,data,paginas,cambiarPagina, buscarData,current }
}