import React, {useEffect,useState} from 'react';
import Titulo from '../../../componentes/Titulo'
import API from '../../../librerias/api';
import history from '../../../librerias/history';
import { useForm } from 'react-hook-form'
import funciones from '../../../librerias/funciones'
import {    useParams} from "react-router-dom";
import obtenerData from '../../../librerias/obtenerData'
import swal from 'sweetalert'
import {    Link  } from "react-router-dom";
import obtenerCatalogoSinDefecto from '../../../librerias/obtenerCatalogoSinDefecto'
import ModalTablaPersonal from '../../../componentes/ModalTablaPersonal'

const Vertrnjoyeriametal =() => {
    const URLENVIOMETALLEER = 'auth/packing/trnjoyeriasenvios-mostrar/'
    let { id } = useParams();
    const trnEnviojoyeria  =obtenerData(URLENVIOMETALLEER,id,1)
    const [catpaquete,setCatpaquete] = useState({})
    const [catalmaceneslo,setCatalmaceneslo] = useState({})
    const { register, handleSubmit, errors,setValue  } = useForm()
    const [catenvio,setCatenvio] = useState({})
    const [personal,setPersonal] = useState({id:null,nombre:null});
    const [personalR,setPersonalR] = useState({id:null,nombre:null});
    const [personal2,setPersonal2] = useState({id:null,nombre:null});
    const [personalR2,setPersonalR2] = useState({id:null,nombre:null});
    const [conHuella,setConHuella] = useState({huella1:true,huella2:true});

    const traerCatpaquete =async () => {
        const catpaquete= await obtenerCatalogoSinDefecto('auth/packing/catpaquete-catalogo')
        if(catpaquete || catpaquete.length===0){
            catpaquete.unshift({value: "", label: '--Selecciona un valor--'})
            setCatpaquete(catpaquete);
        }else{
            setCatpaquete({value: "", label: '--Selecciona un valor--'})
        }
        
    }
    const traerCatenvios =async () => {
        const catenvio= await obtenerCatalogoSinDefecto('auth/packing/catenvios-catalogo')
        if(catenvio || catenvio.length===0){
            catenvio.unshift({value: "", label: '--Selecciona un valor--'})
            setCatenvio(catenvio);
        }else{
            setCatenvio({value: "", label: '--Selecciona un valor--'})
        }
        
    }
    const traerCatalmaceneslo =async () => {
        const catalmaceneslo= await obtenerCatalogoSinDefecto('auth/packing/catalmaceneslugar-catalogo')
        if(catalmaceneslo || catalmaceneslo.length===0){
            catalmaceneslo.unshift({value: "", label: '--Selecciona un valor--'})
            setCatalmaceneslo(catalmaceneslo);
        }else{
            setCatalmaceneslo({value: "", label: '--Selecciona un valor--'})
        }
        
    }

    useEffect(
        ()=>{
        const asignar =(trnEnviojoyeria) =>{
            if(trnEnviojoyeria.id){
                traerCatpaquete();
                traerCatalmaceneslo();
                traerCatenvios();
                setValue('Concepto', trnEnviojoyeria.Concepto)
                setValue('Poliza', trnEnviojoyeria.Poliza)
                setValue('CT', trnEnviojoyeria.CT)
                setValue('Medida', trnEnviojoyeria.Medida)
                setValue('CostoxCT', trnEnviojoyeria.CostoxCT)
                setValue('Calidad', trnEnviojoyeria.Calidad)
                setValue('TipoDiamante', trnEnviojoyeria.TipoDiamante)
                setValue('Destino', trnEnviojoyeria.Destino)
                setValue('Nota', trnEnviojoyeria.Nota) 
                setPersonal({id: trnEnviojoyeria.detalles[0].personalorigen.id,nombre:trnEnviojoyeria.detalles[0].personalorigen.Nombre})
                setPersonalR({id: trnEnviojoyeria.detalles[0].personalresponsable.id,nombre:trnEnviojoyeria.detalles[0].personalresponsable.Nombre})
                setValue('NotaD', trnEnviojoyeria.detalles[0].nota) 
                setValue('tipo', trnEnviojoyeria.detalles[0].tipo) 
                if(trnEnviojoyeria.detalles.length>1){
                    setPersonal2({id: trnEnviojoyeria.detalles[1].personalorigen.id,nombre:trnEnviojoyeria.detalles[1].personalorigen.Nombre})
                    setPersonalR2({id: trnEnviojoyeria.detalles[1].personalresponsable.id,nombre:trnEnviojoyeria.detalles[1].personalresponsable.Nombre})
                    setValue('NotaD2', trnEnviojoyeria.detalles[1].nota) 
                    setValue('tipo2', trnEnviojoyeria.detalles[1].tipo) 
                }
                }

            }
        asignar(trnEnviojoyeria)
        }
    ,[setValue,trnEnviojoyeria]);
    
    const imprimirTicket1= () =>{
        try {
            // console.log(response)
                var contenidoHtml ="<div style='width:100%;  text-align: center;font-family: monospace;' >";
                contenidoHtml +=    trnEnviojoyeria.detalles[0].qrFolio;
                contenidoHtml += "</div>";
                var ticket = document.createElement("div");
                ticket.innerHTML =contenidoHtml;
                var ventimp = window.open(' ', 'folioCreacion1');
                ventimp.document.write( ticket.innerHTML );
                ventimp.document.querySelector("body").style.margin = "0px";
                ventimp.document.close();
                ventimp.print( );
                ventimp.close( );
                
            

        } catch (error) {
            funciones.tratarerrores(error,document.getElementById("respuesta"),error);
            console.log(error)
        }
    }
    const imprimirTicket2= () =>{
        try {
            // console.log(response)
                var contenidoHtml ="<div style='width:100%;  text-align: center;font-family: monospace;' >";
                contenidoHtml +=     trnEnviojoyeria.detalles[1].qrFolio;
                contenidoHtml += "</div>";
                var ticket = document.createElement("div");
                ticket.innerHTML =contenidoHtml;
                var ventimp = window.open(' ', 'folioCreacion1');
                ventimp.document.write( ticket.innerHTML );
                ventimp.document.querySelector("body").style.margin = "0px";
                ventimp.document.close();
                ventimp.print( );
                ventimp.close( );
                
            

        } catch (error) {
            funciones.tratarerrores(error,document.getElementById("respuesta"),error);
            console.log(error)
        }
    }
    const borrarContenido = (e)=>{
        e.target.value="";
    }
    return (
    <>
    <Titulo titulo="Envio joyeria" tituloBajo="Envio joyeria &gt; Ver"></Titulo>


  
    <div className="row">
        <div className="col-xl-12">
            <div className="widget has-shadow">
                <div className="widget-header bordered no-actions d-flex align-items-center">
                    <h3 className="pl-3">Envio joyeria</h3>
                   
                    {/* <Busqueda buscarData={buscarData} icono={icono} ></Busqueda> */}
                </div>
                <div className="widget-body" >
                    <form>
                        <div className="form-group row">
                            <div className="col-6" >
                                <label for="Concepto">Concepto*</label>
                                <input type="text" className="form-control" id="Concepto" name="Concepto" ref={register({ required: true })}  disabled={true}  />
                                {errors.Concepto &&
                                <div className="error-form text-red p-small">
                                Este campo no puede estar vacío!
                                </div>
                                }
                            </div>
                            <div className="col-6" >
                                <label htmlFor="catpaquete_id">Cat. Paquete*</label>
                                <select className="form-control" name="catpaquete_id" id="catpaquete_id" ref={register({ required: true })} disabled={true} >
                                    {
                                        catpaquete.length >0 ?
                                        catpaquete.map((object, index) => (
                                            trnEnviojoyeria.id?
                                            trnEnviojoyeria.catpaquete_id === object.value ?
                                            <option value={object.value} selected key={index}>{object.label}</option>
                                            :
                                            <option value={object.value}  key={index}>{object.label}</option>
                                            :
                                            <option value={object.value}  key={index}>{object.label}</option>
                                        ))
                                        :
                                        ""
                                    }
                                </select>
                                {errors.catpaquete_id &&
                                <div className="error-form text-red p-small">
                                Este campo no puede estar vacío!
                                </div>
                                }
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="col-6" >
                                <label for="rol">Poliza/Número*</label>
                                <input type="text" className="form-control" id="Poliza" name="Poliza" ref={register({ required: true })}  disabled={true}  />
                                {errors.Poliza &&
                                <div className="error-form text-red p-small">
                                Este campo no puede estar vacío!
                                </div>
                                }
                            </div>
                            <div className="col-6" >
                                <label for="rol">CT*</label>
                                <input type="text" className="form-control" id="CT" name="CT" ref={register({ pattern: /^\d*(\.\d{1})?\d{0,1}$/i})}  disabled={true}  />
                                {errors.CT &&
                                <div className="error-form text-red p-small">
                                Este campo necesita ser un número! !
                                </div>
                                }
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="col-6" >
                                <label for="Medida">Medida*</label>
                                <input type="text" className="form-control" id="Medida" name="Medida" ref={register({ pattern: /^\d*(\.\d{1})?\d{0,1}$/i})}  disabled={true}  />
                                {errors.Medida &&
                                <div className="error-form text-red p-small">
                                Este campo no necesita ser un número!!
                                </div>
                                }
                            </div>
                            <div className="col-6" >
                                <label for="rol">Costo - xCT*</label>
                                <input type="text" className="form-control" id="CostoxCT" name="CostoxCT" ref={register({ pattern: /^\d*(\.\d{1})?\d{0,1}$/i})}  disabled={true}  />
                                {errors.CostoxCT &&
                                <div className="error-form text-red p-small">
                                Este campo no puede estar vacío!
                                </div>
                                }
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="col-6" >
                                <label for="rol">Calidad*</label>
                                <input type="text" className="form-control" id="Calidad" name="Calidad" ref={register}  disabled={true}  />
                                {errors.Calidad &&
                                <div className="error-form text-red p-small">
                                Este campo no necesita ser un número!!
                                </div>
                                }
                            </div>
                            <div className="col-6" >
                                <label for="rol">Tipo diamante*</label>
                                <input type="text" className="form-control" id="TipoDiamante" name="TipoDiamante" ref={register} disabled={true}  />
                                {errors.TipoDiamante &&
                                <div className="error-form text-red p-small">
                                Este campo no puede estar vacío!
                                </div>
                                }
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="col-6" >
                                <label almacen_id="rol">Almacen destino*</label>
                                <select className="form-control" name="almacen_id" id="almacen_id"  ref={register({ required: true })} disabled={true} >
                                {
                                    catalmaceneslo.length >0 ?
                                    catalmaceneslo.map((object, index) => (
                                        trnEnviojoyeria.id?
                                        trnEnviojoyeria.almacen_id === object.value ?
                                        <option value={object.value} selected key={index}>{object.label}</option>
                                        :
                                        <option value={object.value}  key={index}>{object.label}</option>
                                        :
                                        <option value={object.value}  key={index}>{object.label}</option>
                                    ))
                                    :
                                    ""
                                }
                                </select>
                            </div>
                            <div className="col-6" >
                                <label for="Destino">Descipción destino</label>
                                <input type="text" className="form-control" id="Destino" name="Destino" ref={register}  disabled={true}  />
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="col-6" >
                                <label for="rol">Nota</label>
                                <input type="text" className="form-control" id="Nota" name="Nota" ref={register}   disabled={true}  />
                            </div>
                        </div>
                        <div id="respuesta" ></div>
                        {/* <div class="text-right">
                            <button type="submit" form="formularioEnc"  id="enviar" className="btn btn-primary ml-2">Enviar</button>
                        </div> */}
                    </form>
                    <hr/>
                    <form >
                    {
                        trnEnviojoyeria.primeraHuella==false?
                            <button className="btn btn-scondary float-right" type="button"   data-toggle="modal" data-target="#modalqr1">Registrar huella(QR)</button>
                        :
                        <span className="float-right" >Huella registrada</span>

                    }
                    
                    <h3 className="pb-3" >Punto A #
                        {
                            trnEnviojoyeria.id?
                            trnEnviojoyeria.detalles[0].folioo
                            :
                            ""
                        }
                    </h3>
                        <div className="form-group row">
                            <div className="col-6" >
                                <label htmlFor="catalmaceneslugaro_id">Almacen*</label>
                                <select className="form-control" name="catalmaceneslugaro_id" id="catalmaceneslugaro_id"  ref={register} required disabled={conHuella.huella1}  >
                                {
                                    catalmaceneslo.length >0 ?
                                    catalmaceneslo.map((object, index) => (
                                        trnEnviojoyeria.id?
                                        trnEnviojoyeria.detalles[0].catalmaceneslugaro_id === object.value ?
                                        <option value={object.value} selected key={index}>{object.label}</option>
                                        :
                                        <option value={object.value}  key={index}>{object.label}</option>
                                        :
                                        <option value={object.value}  key={index}>{object.label}</option>
                                    ))
                                    :
                                    ""
                                }
                                </select>
                                {errors.catalmaceneslugaro_id &&
                                <div className="error-form text-red p-small">
                                Este campo no puede estar vacío!
                                </div>
                                }
                            </div>
                            <div className="col-6" >
                                <label htmlFor="catenvio_id">Tipo de Envío*</label>
                                <select className="form-control" name="catenvio_id" id="catenvio_id"  ref={register} required  disabled={conHuella.huella1}>
                                {
                                    catenvio.length >0 ?
                                    catenvio.map((object, index) => (
                                        trnEnviojoyeria.id?
                                        trnEnviojoyeria.detalles[0].catenvio_id === object.value ?
                                        <option value={object.value} selected key={index}>{object.label}</option>
                                        :
                                        <option value={object.value}  key={index}>{object.label}</option>
                                        :
                                        <option value={object.value}  key={index}>{object.label}</option>
                                    ))
                                    :
                                    ""
                                }
                                </select>
                                {errors.catenvio_id &&
                                <div className="error-form text-red p-small">
                                Este campo no puede estar vacío!
                                </div>
                                }
                            </div> 
                        </div>
                        <div className="form-group row">
                            <div className="col-6" >
                                <label htmlFor="personal_emtrega">Personal entregaddd*</label>
                                <div className="input-group">
                                    <input type="text"  className="form-control" name="Personal" id="Personal" onKeyUp={(e)=>{borrarContenido(e)}}  value={personal.nombre}   ref={register} required disabled={conHuella.huella1}/>
                                    <div className="input-group-append">
                                        <button className="btn btn-outline-secondary" data-toggle="modal" data-target="#exampleModalPersonal" type="button" disabled={conHuella.huella1}>Buscar</button>
                                    </div>
                                </div>
                                {errors.Personal &&
                                <div className="error-form text-red p-small">
                                Este campo no puede estar vacío!
                                </div>
                                }
                            </div>
                            <div className="col-6" >
                                <label htmlFor="personal_emtrega">Personal Responsable*</label>
                                <div className="input-group">
                                    <input type="text" className="form-control"  name="PersonalR" id="PersonalR" onKeyUp={(e)=>{borrarContenido(e)}} value={personalR.nombre}   ref={register} required disabled={conHuella.huella1} />
                                    <div className="input-group-append">
                                        <button className="btn btn-outline-secondary" data-toggle="modal" data-target="#exampleModalPersonalD" type="button" disabled={conHuella.huella1} >Buscar</button>
                                    </div>
                                </div>
                                {errors.personalR &&
                                <div className="error-form text-red p-small">
                                Este campo no puede estar vacío!
                                </div>
                                }
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="col-6" >
                                <label for="Nota">Tipo*</label>
                                <select name="tipo" id="tipo"  ref={register({ required: true })} className="form-control" disabled={true}>
                                    <option value="E">Entrada</option>
                                    <option value="S">Salida</option>
                                </select>
                            </div>
                            <div className="col-6" >
                                <label for="Nota">Nota*</label>
                                <input type="text" className="form-control" id="NotaD" name="NotaD" ref={register} disabled={true} />
                            </div>
                        </div>
                        {/* <div class="text-right">
                            <div id="respuesta1" ></div>
                            <button type="submit" form="formulariodetalle11" id="enviardet1" className="btn btn-primary ml-2">Enviar</button>
                        </div> */}
                    </form>
                    <hr/>
                    <form>
                    {
                        trnEnviojoyeria.id?
                        trnEnviojoyeria.detalles.length>1?
                        trnEnviojoyeria.segundaHuella==false?
                        <button className="btn btn-scondary float-right" type="button"  data-toggle="modal" data-target="#modalqr2">Registrar huella(QR)</button>
                        :
                        ""
                        :
                        ""
                        :
                        ""
                    }
                    <h3 class="pb-3">Punto B #
                    {
                        trnEnviojoyeria.id?
                        trnEnviojoyeria.detalles.length>1?
                        trnEnviojoyeria.detalles[1].folioo
                        :
                        ""
                        :""
                    }
                    </h3>
                        <div className="form-group row">
                            <div className="col-6" >
                                <label htmlFor="catalmaceneslugaro_id2">Almacen*</label>
                                <select className="form-control" name="catalmaceneslugaro_id2" id="catalmaceneslugaro_id2"  ref={register} required  disabled={conHuella.huella2}>
                                {
                                    catalmaceneslo.length >0 ?
                                    catalmaceneslo.map((object, index) => (
                                        trnEnviojoyeria.detalles.length>1?
                                        trnEnviojoyeria.detalles[1].catalmaceneslugaro_id === object.value ?
                                        <option value={object.value} selected key={index}>{object.label}</option>
                                        :
                                        <option value={object.value}  key={index}>{object.label}</option>
                                        :
                                        <option value={object.value}  key={index}>{object.label}</option>
                                    ))
                                    :
                                    ""
                                }
                                </select>
                                {errors.catalmaceneslugaro_id &&
                                <div className="error-form text-red p-small">
                                Este campo no puede estar vacío!
                                </div>
                                }
                            </div>
                            <div className="col-6" >
                                <label htmlFor="catenvio_id2">Tipo de Envío*</label>
                                <select className="form-control"  name="catenvio_id2" id="catenvio_id2"  ref={register} required disabled={conHuella.huella2}>
                                {
                                    catenvio.length >0 ?
                                    catenvio.map((object, index) => (
                                        trnEnviojoyeria.detalles.length>1?
                                        trnEnviojoyeria.detalles[1].catenvio_id === object.value ?
                                        <option value={object.value} selected key={index}>{object.label}</option>
                                        :
                                        <option value={object.value}  key={index}>{object.label}</option>
                                        :
                                        <option value={object.value}  key={index}>{object.label}</option>
                                    ))
                                    :
                                    ""
                                }
                                </select>
                                {errors.catenvio_id &&
                                <div className="error-form text-red p-small">
                                Este campo no puede estar vacío!
                                </div>
                                }
                            </div> 
                        </div>
                        <div className="form-group row">
                            <div className="col-6" >
                                <label htmlFor="personal_emtrega">Personal entrega*</label>
                                <div className="input-group">
                                    <input type="text"  className="form-control" name="personal2" id="personal2"  onKeyUp={(e)=>{borrarContenido(e)}}  value={personal2.nombre}   ref={register} required disabled={conHuella.huella2} />
                                    <div className="input-group-append">
                                        <button className="btn btn-outline-secondary" data-toggle="modal" data-target="#exampleModalPersonal2" type="button">Buscar</button>
                                    </div>
                                </div>
                                {errors.personal2 &&
                                <div className="error-form text-red p-small">
                                Este campo no puede estar vacío!
                                </div>
                                }
                            </div>
                            <div className="col-6" >
                                <label htmlFor="personal_emtrega">Personal Responsable*</label>
                                <div className="input-group">
                                    <input type="text" className="form-control" name="personalR2" id="personalR2"  onKeyUp={(e)=>{borrarContenido(e)}}  value={personalR2.nombre}   ref={register} required disabled={conHuella.huella2} />
                                    <div className="input-group-append">
                                        <button className="btn btn-outline-secondary" data-toggle="modal" data-target="#exampleModalPersonalD2" type="button" disabled={conHuella.huella2}>Buscar</button>
                                    </div>
                                </div>
                                {errors.personalR &&
                                <div className="error-form text-red p-small">
                                Este campo no puede estar vacío!
                                </div>
                                }
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="col-6" >
                                <label for="Tipo">Tipo*</label>
                                <select name="tipo2" id="tipo2"  ref={register({ required: true })} className="form-control"  disabled={conHuella.huella2}>
                                    <option value="E">Entrada</option>
                                    <option value="S">Salida</option>
                                </select>
                            </div>
                            <div className="col-6" >
                                <label for="NotaD2">Nota*</label>
                                <input type="text" className="form-control" id="NotaD2" name="NotaD2" ref={register}  disabled={conHuella.huella2} />
                            </div>
                        </div>
                        {/* <div class="text-right">
                            <div id="respuesta3" ></div>
                            <button type="submit" form="formulariodetalle12" id="enviardet2" className="btn btn-primary ml-2">Enviar</button>
                        </div> */}

                        <div class="text-right pt-3">
                            <Link to="/trackingjoyeria" className="btn btn-secondary">Regresar envíos metal</Link>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
    <ModalTablaPersonal titulo="Personal" urlBusqueda="auth/packing/personal-sinpaginacion" elegirElemento={setPersonal} idModal="exampleModalPersonal" />
    <ModalTablaPersonal titulo="Personal" urlBusqueda="auth/packing/personal-sinpaginacion" elegirElemento={setPersonalR} idModal="exampleModalPersonalD" />
    <ModalTablaPersonal titulo="Personal" urlBusqueda="auth/packing/personal-sinpaginacion" elegirElemento={setPersonal2} idModal="exampleModalPersonal2" />
    <ModalTablaPersonal titulo="Personal" urlBusqueda="auth/packing/personal-sinpaginacion" elegirElemento={setPersonalR2} idModal="exampleModalPersonalD2" />
    <div class="modal fade" id="modalqr1" tabindex="-1" role="dialog" aria-labelledby="modalqr1Title" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="modalqr1Title">Lectura de QR<span id="foliocita"></span></h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body row">
                    <div className="col-12 col-sm-12" >
                        <h3 className="text-center">QR punto A</h3>
                        <div className="text-center" >
                            {
                                trnEnviojoyeria.id?
                                trnEnviojoyeria.detalles[0].folioo
                                :
                                ""
                            }
                        </div>
                        {
                            trnEnviojoyeria.id?
                            <div className="text-center qr-contenedor" style={{width:"100%"}}  dangerouslySetInnerHTML={{__html: trnEnviojoyeria.detalles[0].qrFolio}} />
                            :
                            ""
                        }
                        
                    </div>
                </div>
                <div id="respuestaerror" >
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Cerrar</button>
                    <button className="btn btn-primary " onClick={imprimirTicket1} >Imprimir</button>
                </div>
            </div>
        </div>
    </div>
    <div class="modal fade" id="modalqr2" tabindex="-1" role="dialog" aria-labelledby="modalqr2Title" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="modalqr2Title">Lectura de QR<span id="foliocita"></span></h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
                </button>
            </div>
                <div class="modal-body row">
                {
                        trnEnviojoyeria.id?
                        trnEnviojoyeria.detalles.length>1?
                        <div className="col-12 col-sm-12" >
                            <h3 className="text-center">QR punto B</h3>
                            <div className="text-center" >
                                {
                                    trnEnviojoyeria.id?
                                    trnEnviojoyeria.detalles[1].folioo
                                    :
                                    ""
                                }
                                
                            </div>
                            {
                                trnEnviojoyeria.id?
                                <div className="text-center qr-contenedor" style={{width:"100%"}}  dangerouslySetInnerHTML={{__html: trnEnviojoyeria.detalles[1].qrFolio}} />
                                :
                                ""
                            }
                        </div>
                        :
                        ""
                        :""
                    }

                </div>
                <div id="respuestaerror" >
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Cerrar</button>
                    <button className="btn btn-primary " onClick={imprimirTicket2} >Imprimir</button>
                </div>
            </div>
        </div>
    </div>
    </>

    )
}
export default Vertrnjoyeriametal;