import React from 'react'
import {Link} from 'react-router-dom'

export default({paginas,cambiarPagina,current}) =>{
    if(!paginas || !current ){
        return (<></>);
    }
    if(paginas[0])
    if(paginas[0].label !==1){
       paginas.shift();
       
    }
    var ultimo = paginas.length
    if(paginas[ultimo-1])
    if(paginas[ultimo-1].label ==="Siguiente &raquo;"){
        paginas.pop();
        
     }

    return (
        
        <nav>
        <ul className="pagination pull-left">
            <li className="page-item">
                <span className="page-link">{`Mostrando ${current.current_page} de ${current.last_page}`}</span>
            </li>
           
        </ul>
        <ul className="pagination pull-right">
            <li className={current.current_page===1 ? "page-item disabled" : "page-item"  }>
                <Link className="page-link" to="#" onClick={() => cambiarPagina(current.current_page-1)} >Atras</Link>
            </li>
            <li className={current.current_page===current.last_page   ? "page-item disabled" : "page-item"  }>
                <Link className="page-link" to="#"  onClick={() => cambiarPagina(current.current_page+1)}>Siguiente</Link>
            </li>
        </ul>

        </nav>
    )
}
